import { Button, Typography } from "@mui/material";
import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import Tooltip from "../../../components/Common/Tooltip";
import NoteAltOutlinedIcon from "@mui/icons-material/NoteAltOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import theme from "../../../theme";
import { htmlParser } from "../../../Utility/constants";

const EditQues = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 0 });
  const surveyName = useSelector(
    (state) => state?.survey?.surveyBuilderData?.surveyData?.survey?.name
  );

  const columns = useMemo(
    () => [
      {
        accessorKey: "operations",
        header: "Operations",
        size: 50,
        Cell: ({ row }) => (
          <>
            <Tooltip title="Quick field edit" arrow>
              <NoteAltOutlinedIcon
                sx={{
                  cursor: "pointer",
                  width: 40,
                  height: 40,
                  color: theme.palette.green.main,
                  background: theme.palette.green.light,
                  borderRadius: "10px",
                  padding: "10px",
                  marginRight: "10px",
                }}
              />
            </Tooltip>
            <Tooltip title="Delete an answer field" arrow>
              <CloseOutlinedIcon
                sx={{
                  cursor: "pointer",
                  width: 40,
                  height: 40,
                  color: theme.palette.blue.main,
                  background: theme.palette.blue.light,
                  borderRadius: "10px",
                  padding: "10px",
                }}
              />
            </Tooltip>
          </>
        ),
      },
      {
        accessorKey: "fieldName",
        header: "Field Heading & Field Label",
        // size: 200,
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data,
    state: { pagination },
    enableRowOrdering: true,
    enableSorting: false,
    enableTopToolbar: false,
    enableBottomToolbar: false,
    enableColumnActions: false,
    initialState: { density: "compact" },
    muiRowDragHandleProps: ({ table }) => ({
      onDragEnd: () => {
        const { draggingRow, hoveredRow } = table.getState();
        if (hoveredRow && draggingRow) {
          data.splice(
            hoveredRow.index,
            0,
            data.splice(draggingRow.index, 1)[0]
          );
          setData([...data]);
        }
      },
    }),
  });
  const buttonStyle = {
    marginRight: "20px",
    marginBottom: "15px",
  };

  return (
    <>
      <div style={{ marginBottom: "15px" }}>
        <div>
          <Typography variant="label" color="primary">
            {htmlParser(surveyName)}
          </Typography>
        </div>
      </div>

      <div>
        <Button
          variant="outlined"
          size="small"
          component="label"
          style={buttonStyle}
        >
          Edit question
        </Button>
        <Button
          size="small"
          variant="outlined"
          component="label"
          style={buttonStyle}
        >
          Add Question
        </Button>
        <Button
          variant="outlined"
          size="small"
          component="label"
          style={buttonStyle}
        >
          Delete question
        </Button>
        <Button
          variant="outlined"
          size="small"
          component="label"
          style={buttonStyle}
        >
          Preview Survey
        </Button>
      </div>
      <div style={{ marginBottom: "20px" }}>
        <Typography variant="label">
          Answer Field(s) in Question. Available operations: reorder, delete,
          aggregate single response (radio) field, add data range to numeric
          field, and add preset rule.
        </Typography>
      </div>
      <MaterialReactTable table={table} />
    </>
  );
};

export default EditQues;
