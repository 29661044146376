import {
  Box,
  CircularProgress,
  Snackbar,
  Typography,
  Modal,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import Tooltip from "../../../components/Common/Tooltip";
import NoteAltOutlinedIcon from "@mui/icons-material/NoteAltOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import UnfoldMoreOutlinedIcon from "@mui/icons-material/UnfoldMoreOutlined";
import ListAltIcon from "@mui/icons-material/ListAlt";
import theme from "../../../theme";
import {
  deleteQuestion,
  deleteQuestionGroup,
  updateQuestionGroupOrder,
  updateQuestionOrder,
} from "../../Survey/Survey.service";
import {
  setEditQuesGrpId,
  setEditQuesId,
  setQuesEditSource,
  triggerSidebarRefresh,
  setAddQuesToGrp,
  setSelectedField,
  setSavedQuesGrp,
  setSavedQues,
} from "../../Survey/Survey.slice";
import SurveyActionButtons from "./SurveyActionButtons";
import {
  getTextFromHTML,
  htmlParser,
  truncateText,
} from "../../../Utility/constants";
import ConfirmDialog from "../../../components/Common/ConfirmDialog";
import MuiAlert from "@mui/material/Alert";
import QuickEditQues from "./QuickEditQues";
import QuickEditQuesGrp from "./QuickEditQuesGrp";
import QuickMoveGroup from "./QuickMoveGroup";
import { useNavigate } from "react-router-dom";

const EditPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [questionGroups, setQuestionGroups] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 0 });
  const [secName, setSecName] = useState(null);
  const [confirmTitle, setConfirmTitle] = useState("");
  const [confirmQuesId, setConfirmQuesId] = useState({
    quesId: "",
    quesType: "",
  });
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");

  const [openEditQuesModal, setOpenEditQuesModal] = useState(false);
  const [openQuestion, setOpenQuestion] = useState(null);

  const [openMoveGroupModal, setOpenMoveGroupModal] = useState(false);
  const [moveGroupId, setMoveGroupId] = useState(null);
  const [moveGroupName, setMoveGroupName] = useState(null);

  const [openEditQuesGrpModal, setOpenEditQuesGrpModal] = useState(false);
  const [openQuestionGrp, setOpenGrpQuestion] = useState(null);

  const userName = useSelector(
    (state) => state?.survey?.loggedInUserData?.authorizedUser?.name
  );
  const surveyName = useSelector(
    (state) => state?.survey?.surveyBuilderData?.surveyData?.survey?.name
  );
  const selectedPageName = useSelector(
    (state) => state?.survey?.surveyBuilderData?.selectedPage?.page?.name
  );
  const selectedPageId = useSelector(
    (state) => state?.survey?.surveyBuilderData?.selectedPage?.page?.id
  );

  const selectedPageQuesGrp = useSelector(
    (state) => state?.survey?.surveyBuilderData?.selectedPage?.questionGroups
  );

  const selectedSection = useSelector(
    (state) => state?.survey?.surveyBuilderData?.selectedSec
  );

  const sidebarLoading = useSelector(
    (state) => state?.survey?.surveyBuilderData?.sidebarLoading
  );

  const quesEditSource = useSelector(
    (state) => state?.survey?.surveyBuilderData.quesEditSource
  );

  const savedQuesGrp = useSelector(
    (state) => state?.survey?.surveyBuilderData.savedQuesGrp
  );

  const savedQues = useSelector(
    (state) => state?.survey?.surveyBuilderData.savedQues
  );

  const addQuesToGrp = useSelector(
    (state) => state?.survey?.surveyBuilderData.addQuesToGrp
  );

  useEffect(() => {
    dispatch(setAddQuesToGrp(false));
    dispatch(setSavedQuesGrp(null));
    dispatch(setSavedQues(null));
  }, [addQuesToGrp, savedQues, savedQuesGrp]);

  useEffect(() => {
    if (quesEditSource === "editPage") {
      dispatch(setQuesEditSource(null));
    }
  }, [quesEditSource]);

  useEffect(() => {
    if (selectedPageQuesGrp?.length > 0) {
      const quesGroups = [];
      const ques = [];
      const quesGrpInPage = selectedPageQuesGrp.flatMap((quesGrp) => {
        if (
          quesGrp.questions.length === 1 &&
          quesGrp.groupType.name === "standardgrp"
        ) {
          quesGroups.push(quesGrp.id);
          return [
            {
              id: quesGrp.id,
              quesType: "quesGrp",
              quesName: `${getTextFromHTML(quesGrp.questions[0].label) || ""} ${
                getTextFromHTML(quesGrp.questions[0].name) || ""
              }`,
              quesGrpId: quesGrp.id,
              quesGrp,
            },
          ];
        } else {
          const quesName =
            quesGrp?.name && quesGrp?.name !== "nbsp;"
              ? `Group: ${getTextFromHTML(quesGrp.name)}`
              : `(The following ${quesGrp.questions.length} questions appear together as a group)`;

          const groupEntry = {
            id: quesGrp.id,
            quesType: "quesGrp",
            quesName: quesName,
            quesGrpId: quesGrp.id,
            quesGrp,
          };

          if (
            (!quesGrp?.name || quesGrp?.name === "nbsp;") &&
            quesGrp.questions.length > 1
          ) {
            groupEntry.firstQues = htmlParser(
              `${quesGrp.questions[0].label || ""} ${
                quesGrp.questions[0].name || ""
              }`
            );
          }

          quesGroups.push(quesGrp.id);

          const quesArray = [];
          const questionEntries = quesGrp.questions.map((question) => {
            quesArray.push(question.id);
            return {
              id: question.id,
              quesType: "ques",
              quesName: htmlParser(
                `${getTextFromHTML(question.label) || ""} ${
                  getTextFromHTML(question.name) || ""
                }`
              ),
              quesGrpId: quesGrp.id,
              quesGrp,
            };
          });
          if (quesArray.length > 0) {
            ques.push(quesArray);
          }
          return [groupEntry, ...questionEntries];
        }
      });
      setQuestionGroups(quesGroups);
      setQuestions(ques);
      setData(quesGrpInPage);
      setPagination({ pageIndex: 0, pageSize: quesGrpInPage?.length });
    } else setData([]);
  }, [selectedPageQuesGrp]);
  useEffect(() => {
    setSecName(selectedSection?.section?.name);
  }, [selectedSection]);

  const handleQuesDelete = async () => {
    try {
      let response;
      if (confirmQuesId.quesType === "quesGrp") {
        response = await deleteQuestionGroup(confirmQuesId.quesId);
      } else if (confirmQuesId.quesType === "ques") {
        response = await deleteQuestion(confirmQuesId.quesId);
      }

      if (response?.status === 200 && response?.data === true) {
        setSuccessSnackbarOpen(true);
        setAlertMsg("Question deleted successfully");
        setAlertSeverity("success");
        setConfirmTitle("");
        setConfirmQuesId({
          quesId: "",
          quesType: "",
        });
        setConfirmOpen(false);
        dispatch(triggerSidebarRefresh());
      } else {
        setSuccessSnackbarOpen(true);
        setAlertMsg("Error deleting question");
        setAlertSeverity("error");
        setConfirmTitle("");
        setConfirmQuesId({
          quesId: "",
          quesType: "",
        });
        setConfirmOpen(false);
      }
    } catch (error) {
      console.log("Error deleting survey data.", error);
      return null;
    }
  };

  const openDeleteSurveyData = async (quesId, name, quesType, firstQues) => {
    if (firstQues !== undefined) {
      setConfirmTitle(
        `Deleting ${truncateText(firstQues, 35)} - Press OK to proceed.`
      );
    } else {
      setConfirmTitle(
        `Deleting ${truncateText(name, 35)} - Press OK to proceed.`
      );
    }
    setConfirmQuesId({ quesId, quesType });
    setConfirmOpen(true);
  };

  const openQTextEdit = (rowId, rowQtype, rowQuesGrpId) => {
    const currentGroup = selectedPageQuesGrp.filter(
      (field) => field.id === rowQuesGrpId
    )[0];

    if (rowQtype === "quesGrp") {
      let questionGroupObject = {
        id: currentGroup.id,
        groupLabel: currentGroup?.groupLabel || null,
        name: currentGroup.name || null,
        description: currentGroup.description || null,
        descriptionPosition: currentGroup.descriptionPosition || "Below Title",
        helpText: currentGroup.helpText || null,
        sequence: currentGroup.sequence || 0,
        shortName: currentGroup.shortName || null,
        tableWidthPct: currentGroup.tableWidthPct || null,
        questionWidthPct: currentGroup.questionWidthPct || null,
        hideGroup: currentGroup.hideGroup || false,
        modifiedBy: userName,
        page: { id: selectedPageId },
      };
      setOpenGrpQuestion(questionGroupObject);
      setOpenEditQuesGrpModal(true);
    } else if (rowQtype === "ques") {
      const currentQues = currentGroup.questions.filter(
        (field) => field.id === rowId
      )[0];

      setOpenQuestion(currentQues);
      setOpenEditQuesModal(true);
    }
  };

  const openMoveGroup = (id, name) => {
    setMoveGroupId(id);
    setMoveGroupName(name);
    setOpenMoveGroupModal(true);
  };
  const handleCloseMoveGrpModal = () => {
    setMoveGroupId(null);
    setMoveGroupName(null);
    setOpenMoveGroupModal(false);
  };

  const handleRefreshMoveGrpModal = () => {
    handleCloseMoveGrpModal();
    setSuccessSnackbarOpen(true);
    setAlertMsg("Group moved to another page successfully");
    setAlertSeverity("success");
    setTimeout(() => {
      dispatch(triggerSidebarRefresh());
    }, 2000);
  };

  const handleEditQues = (row) => {
    let quesId = row?.original?.id;

    if (row?.original?.quesType === "quesGrp") {
      quesId = row?.original?.quesGrp?.questions?.[0]?.id;
    }
    const quesGrp = row?.original?.quesGrp;

    if (
      quesGrp.groupType.name === "standardgrp" ||
      quesGrp.groupType.name === "checkboxgrp" ||
      quesGrp.groupType.name === "commongrp" ||
      quesGrp.groupType.name === "radiogrp"
    ) {
      dispatch(setEditQuesGrpId(quesGrp?.id));
      dispatch(setEditQuesId(quesId));
      dispatch(setQuesEditSource("editPage"));
      if (quesGrp.groupType.name !== "standardgrp") {
        dispatch(setSelectedField(quesGrp.groupType.name));
      }

      navigate("/add-question");
    }
  };

  const handleAddQues = (row) => {
    const quesGrp = row?.original?.quesGrp;
    dispatch(setAddQuesToGrp(true));
    dispatch(setEditQuesGrpId(quesGrp?.id));
    navigate("/add-question");
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "operations",
        header: "Operations",
        size: 70,
        Cell: ({ row }) => (
          <>
            <Tooltip title="Quick question text edit" arrow>
              <NoteAltOutlinedIcon
                sx={{
                  cursor: "pointer",
                  width: 35,
                  height: 35,
                  color: theme.palette.green.main,
                  background: theme.palette.green.light,
                  borderRadius: "9px",
                  padding: "9px",
                  marginRight: "9px",
                }}
                onClick={() => {
                  openQTextEdit(
                    row?.original?.id,
                    row?.original?.quesType,
                    row?.original?.quesGrpId
                  );
                }}
              />
            </Tooltip>

            <Tooltip title="Insert a spacer for the pdf output" arrow>
              <UnfoldMoreOutlinedIcon
                sx={{
                  cursor: "pointer",
                  width: 35,
                  height: 35,
                  color: theme.palette.orange.main,
                  background: theme.palette.orange.light,
                  borderRadius: "9px",
                  padding: "9px",
                  marginRight: "9px",
                }}
              />
            </Tooltip>
            <Tooltip title="Delete a question" arrow>
              <CloseOutlinedIcon
                sx={{
                  cursor: "pointer",
                  width: 35,
                  height: 35,
                  color: theme.palette.error.main,
                  background: theme.palette.error.light,
                  borderRadius: "9px",
                  padding: "9px",
                  marginRight: "9px",
                }}
                onClick={() => {
                  openDeleteSurveyData(
                    row?.original?.id,
                    row?.original?.quesName,
                    row?.original?.quesType,
                    row?.original?.firstQues
                  );
                }}
              />
            </Tooltip>
            {row?.original?.quesType == "quesGrp" &&
              row?.original?.quesGrp?.groupType?.name === "standardgrp" && (
                <Tooltip title="Add question" arrow>
                  <AddCircleOutlineIcon
                    sx={{
                      cursor: "pointer",
                      width: 35,
                      height: 35,
                      color: theme.palette.blue.main,
                      background: theme.palette.blue.light,
                      borderRadius: "9px",
                      padding: "9px",
                      marginRight: "9px",
                    }}
                    onClick={() => {
                      handleAddQues(row);
                    }}
                  />
                </Tooltip>
              )}
            {row?.original?.quesType == "quesGrp" && (
              <Tooltip title="Move Group to Another Page" arrow>
                <ListAltIcon
                  sx={{
                    cursor: "pointer",
                    width: 35,
                    height: 35,
                    color: theme.palette.orange.main,
                    background: theme.palette.yellow.light,
                    borderRadius: "9px",
                    padding: "9px",
                  }}
                  onClick={() => {
                    openMoveGroup(row?.original?.id, row?.original?.quesName);
                  }}
                />
              </Tooltip>
            )}
          </>
        ),
      },
      {
        accessorKey: "quesName",
        header: "Question",
        Cell: ({ row }) => (
          <>
            <Typography variant="string" sx={{ whiteSpace: "pre-line" }}>
              {row?.original?.quesType === "ques" ||
              (row?.original?.quesType === "quesGrp" &&
                row?.original?.quesGrp?.questions?.length === 1 &&
                row?.original?.quesGrp.groupType.name === "standardgrp") ? (
                <span
                  className="linkRegularText"
                  title="Edit Question"
                  style={{
                    marginLeft: "10px",
                  }}
                  onClick={() => {
                    handleEditQues(row);
                  }}
                >
                  {row.original?.quesName}
                </span>
              ) : row?.original?.quesGrp?.groupType?.name === "standardgrp" ? (
                <span>
                  <i>{row.original?.quesName}</i>
                </span>
              ) : (
                <span
                  className="linkRegularText"
                  title="Edit Question Group"
                  onClick={() => {
                    handleEditQues(row);
                  }}
                >
                  <i>{row.original?.quesName}</i>
                </span>
              )}
            </Typography>
          </>
        ),
      },
    ],
    [questionGroups]
  );

  const updateQuesGrpOrder = async (newQuesGrpOrder) => {
    try {
      await updateQuestionGroupOrder(newQuesGrpOrder);
    } catch (error) {
      console.error("Error updating Question Group order", error);
    } finally {
      dispatch(triggerSidebarRefresh());
    }
  };

  const updateQuesOrder = async (newQuesOrder) => {
    try {
      await updateQuestionOrder(newQuesOrder);
    } catch (error) {
      console.error("Error updating Question order", error);
    } finally {
      dispatch(triggerSidebarRefresh());
    }
  };

  const handleCloseEditModal = () => {
    setOpenEditQuesModal(false);
    setOpenQuestion(null);
  };

  const handleRefreshQuestion = () => {
    handleCloseEditModal();
    setSuccessSnackbarOpen(true);
    setAlertMsg("Question updated successfully");
    setAlertSeverity("success");
    dispatch(triggerSidebarRefresh());
  };

  const handleCloseEditGrpModal = () => {
    setOpenEditQuesGrpModal(false);
    setOpenEditQuesGrpModal(null);
  };

  const handleRefreshQuestionGrp = () => {
    handleCloseEditGrpModal();
    setSuccessSnackbarOpen(true);
    setAlertMsg("Question group updated successfully");
    setAlertSeverity("success");
    dispatch(triggerSidebarRefresh());
  };

  const table = useMaterialReactTable({
    columns,
    data,
    enableRowOrdering: true,
    enableSorting: false,
    enableTopToolbar: false,
    enableBottomToolbar: false,
    state: { pagination },
    enableColumnActions: false,
    initialState: { density: "compact" },
    layoutMode: "grid",
    muiRowDragHandleProps: ({ table }) => ({
      onDragEnd: () => {
        const { draggingRow, hoveredRow } = table.getState();
        if (hoveredRow && draggingRow) {
          const newData = [...data];
          newData.splice(
            hoveredRow.index,
            0,
            newData.splice(draggingRow.index, 1)[0]
          );

          if (draggingRow.index !== hoveredRow.index) {
            const quesIds = [];
            const quesGrpIds = [];
            newData.map((item, index) => {
              if (
                questionGroups.includes(draggingRow.original.id) &&
                questionGroups.includes(hoveredRow.original.id)
              ) {
                if (questionGroups.includes(item.id)) {
                  quesGrpIds.push({
                    id: item.id,
                  });
                }
              }

              function isSameQuesGrp(id1, id2) {
                for (let array of questions) {
                  if (array.includes(id1) && array.includes(id2)) {
                    return { found: true, array: array };
                  }
                }
                return { found: false, array: null };
              }
              if (
                isSameQuesGrp(draggingRow.original.id, hoveredRow.original.id)
                  .found
              ) {
                const quesArray = isSameQuesGrp(
                  draggingRow.original.id,
                  hoveredRow.original.id
                ).array;

                if (quesArray.includes(item.id)) {
                  quesIds.push({
                    id: item.id,
                  });
                }
              }
            });

            quesGrpIds.forEach((item, index) => {
              item.sequence = index;
            });

            if (quesGrpIds.length > 0) {
              updateQuesGrpOrder({ idSequenceList: quesGrpIds });
              setData(newData);
            }

            quesIds.forEach((item, index) => {
              item.sequence = index;
            });

            if (quesIds.length > 0) {
              updateQuesOrder({ idSequenceList: quesIds });
              setData(newData);
            }
          }
        }
      },
    }),
  });

  return sidebarLoading ? (
    <>
      <Box
        display="flex"
        sx={{ height: "100vh", width: "100%" }}
        bgcolor="#d9e0ed"
        alignItems="center"
        justifyContent="center"
      >
        <CircularProgress />
      </Box>
    </>
  ) : (
    <>
      <div style={{ marginBottom: "15px" }}>
        <div>
          <Typography variant="label" color="primary">
            {htmlParser(surveyName)}
          </Typography>
        </div>
        <div>
          <Typography variant="label" color="primary">
            {htmlParser(secName)}, {htmlParser(selectedPageName)}
          </Typography>
        </div>
      </div>

      <SurveyActionButtons page="Page" />
      <div style={{ marginBottom: "20px" }}>
        <Typography variant="label">
          Questions on Page. Available operations: reorder, edit, add skip rule,
          add pdf spacer, and delete question
        </Typography>
      </div>
      <MaterialReactTable table={table} />
      <ConfirmDialog
        title={confirmTitle}
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={handleQuesDelete}
        pageName="ModifySurvey"
      ></ConfirmDialog>
      <Snackbar
        open={successSnackbarOpen}
        autoHideDuration={2000}
        onClose={() => setSuccessSnackbarOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          onClose={() => setSuccessSnackbarOpen(false)}
          severity={alertSeverity}
          variant="filled"
        >
          {alertMsg}
        </MuiAlert>
      </Snackbar>

      <Modal open={openEditQuesModal} onClose={handleCloseEditModal}>
        <>
          <QuickEditQues
            handleCloseEditModal={handleCloseEditModal}
            handleRefreshQuestion={handleRefreshQuestion}
            openQuestion={openQuestion}
          />
        </>
      </Modal>
      <Modal open={openEditQuesGrpModal} onClose={handleCloseEditGrpModal}>
        <>
          <QuickEditQuesGrp
            handleCloseEditGrpModal={handleCloseEditGrpModal}
            handleRefreshQuestionGrp={handleRefreshQuestionGrp}
            openQuestionGrp={openQuestionGrp}
          />
        </>
      </Modal>
      <Modal open={openEditQuesGrpModal} onClose={handleCloseEditGrpModal}>
        <>
          <QuickEditQuesGrp
            handleCloseEditGrpModal={handleCloseEditGrpModal}
            handleRefreshQuestionGrp={handleRefreshQuestionGrp}
            openQuestionGrp={openQuestionGrp}
          />
        </>
      </Modal>
      <Modal open={openMoveGroupModal} onClose={handleCloseMoveGrpModal}>
        <>
          <QuickMoveGroup
            handleCloseMoveGrpModal={handleCloseMoveGrpModal}
            handleRefreshMoveGrpModal={handleRefreshMoveGrpModal}
            moveGroupId={moveGroupId}
            moveGroupName={moveGroupName}
          />
        </>
      </Modal>
    </>
  );
};

export default EditPage;
