export const initNewVrData = {
  id: null,
  logical: "=",
  mathematical: ":=",
  numeric: "ceil",
  alphafun: "append",
  alphaconst: "",
  numericconst: "1",
  panelattr: "state",
  var1: "l1",
  var2: "D1",
  var3: "V1",
  var4: "AND",
  var5: "OR",
  var6: "(",
  var7: ")",
  var8: ",",
  userText: "",
  expression: "",
  fieldLabels: "",
  errorText: "",
  requiredAction: "Fix",
  ruleModeExceptionStateIds: [],
  ruleType: "Complex Rule",
  sequence: 0,
};
export const logicalOperators = [
  "=",
  "!=",
  ">",
  ">=",
  "<",
  "<=",
  "=null",
  "!=null",
  "ifthenelse(",
];
export const mathOperators = [":=", "+", "-", "*", "/", "&"];
export const numericFunctions = [
  "ceil",
  "exp",
  "mod",
  "nvl",
  "pow",
  "round",
  "todecimal",
  "tointeger",
  "tostring",
];
export const alphaFunctions = [
  "append",
  "contains",
  "containsany",
  "dateadd",
  "datesub",
  "length",
  "lower",
  "notcontains",
  "remove",
  "substr",
  "todate",
  "upper",
];
export const panelAttribuites = [
  "city",
  "county",
  "customId",
  "name",
  "region",
  "state",
  "urbanFlag",
  "zipCode",
  "attribute1",
  "attribute2",
  "attribute3",
  "attribute4",
  "attribute5",
  "attribute6",
  "attribute7",
  "attribute8",
  "attribute9",
  "attribute10",
  "attribute11",
  "attribute12",
  "attribute13",
  "attribute14",
  "attribute15",
  "attribute16",
  "attribute17",
  "attribute18",
  "attribute19",
  "attribute20",
];
export const vLevel1 = [
  "l1",
  "l2",
  "l3",
  "l4",
  "l5",
  "l6",
  "l7",
  "l8",
  "l9",
  "l10",
  "lLoop",
];
export const vLevel2 = [
  "D1",
  "D2",
  "D3",
  "D4",
  "D5",
  "D6",
  "D7",
  "D8",
  "D9",
  "D10",
];
export const vLevel3 = [
  "V1",
  "V2",
  "V3",
  "V4",
  "V5",
  "V6",
  "V7",
  "V8",
  "V9",
  "V10",
];
export const isEditorFieldsCase = (name) => {
  const selCase =
    name !== "numericconst" &&
    name !== "alphaconst" &&
    name !== "fieldLabels" &&
    name !== "expression" &&
    name !== "requiredAction" &&
    name !== "errorText" &&
    name !== "ruleModeExceptionStateIds";
  return selCase;
};
export const findLevel = (
  ruleEditorLevel,
  surveyId,
  selectedPageId,
  selectedSecId
) => {
  let editorLevel = {};
  if (ruleEditorLevel === "SurveyLevel") {
    editorLevel = { surveyId: surveyId };
  } else if (ruleEditorLevel === "PageLevel") {
    editorLevel = { pageId: selectedPageId };
  } else if (ruleEditorLevel === "SectionLevel") {
    editorLevel = { sectionId: selectedSecId };
  }
  return editorLevel;
};
export const findRedirectVrListPage = (ruleEditorLevel) => {
  let vrPage = {};
  if (ruleEditorLevel === "SurveyLevel") {
    vrPage = { viewIndex: 8, source: "viewSurveyVrsButton" };
  } else if (ruleEditorLevel === "SectionLevel") {
    vrPage = { viewIndex: 9, source: "viewSectionVrsButton" };
  } else if (ruleEditorLevel === "PageLevel") {
    vrPage = { viewIndex: 10, source: "viewPageVrsButton" };
  }
  return vrPage;
};
