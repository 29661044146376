import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import NoteAltOutlinedIcon from "@mui/icons-material/NoteAltOutlined";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import {
  MenuItem,
  Select,
  FormControl,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import TextField from "../../../../components/Common/TextField";
import { getTextFromHTML } from "../../../../Utility/constants";
import theme from "../../../../theme";
import {
  fieldTypeOptions,
  initialresData,
  mapResponseData,
  emptyFieldRow,
} from "./AddQuesConstants";
import {
  createQuestion,
  updateQuestion,
  deleteQuestion,
  createStandardMatrixField,
  updateField,
  createField,
  deleteField,
} from "../../../Survey/Survey.service";
import CheckboxAttributes from "./CheckboxAttributes";
import QuickQuestionEdit from "./QuickQuestionEdit";
import SecFieldAttributes from "./SecFieldAttributes";
import {
  setTriggerMatrixGrp,
  setSavedField,
  setSavedComField,
} from "../../../Survey/Survey.slice";
function showResponseHeaders(genCols) {
  return (
    <>
      {genCols.map((col) => {
        return (
          <TableCell>Secondary field label / field type * / edit </TableCell>
        );
      })}
    </>
  );
}
function MultipleResponseTable({ qGroupId, totalCols, matrixquestions }) {
  const dispatch = useDispatch();
  const selectedField = useSelector(
    (state) => state?.survey?.surveyBuilderData.selectedField
  );

  const userName = useSelector(
    (state) => state?.survey?.loggedInUserData?.authorizedUser?.name
  );

  const genCols = Array(totalCols)
    .fill()
    .map((x, i) => ++i);
  const [responseData, setResponseData] = useState(matrixquestions);
  const [fieldTypeName, setFieldTypeName] = useState(null);

  const [isCreateUpdateQues, setIsCreateUpdateQues] = useState(false);
  const [qIndex, setQIndex] = useState(null);

  const [isCreateUpdateSFields, setIsCreateUpdateSFields] = useState(false);
  const [secIndex, setSecIndex] = useState({});

  const [openAttrModal, setOpenAttrModal] = useState(false);
  const [attrMatrixRow, setAttrMatrixRow] = useState({});
  const [identity, setIdentity] = useState(null);

  const [openEditQuesModal, setOpenEditQuesModal] = useState(false);
  const [openQuestion, setOpenQuestion] = useState({});

  const [openSecAttrModal, setOpenSecAttrModal] = useState(false);
  const [secAttrRow, setSecAttrRow] = useState({});

  const sortedResData = initialresData.sort((a, b) => a.sequence - b.sequence);

  const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");

  useEffect(() => {
    if (selectedField) {
      const matrixtype =
        selectedField === "checkboxgrp" ? "CheckBox Field" : "Radio Field";
      setFieldTypeName(matrixtype);
    }
  }, [selectedField]);

  const addNewQuestion = () => {
    const baseMatrixField = emptyFieldRow();
    let seqNumber = 2001;
    let fSet = [];
    let qSet = {
      id: null,
      dateCreated: null,
      dateModified: null,
      createdBy: null,
      modifiedBy: null,
      label: "",
      name: "",
      helptext: "",
      sequence: 0,
      fields: [],
    };
    fSet.push(baseMatrixField);
    for (let colNumber = 1; colNumber <= genCols.length; colNumber++) {
      let firstN = emptyFieldRow();
      firstN.sequence = seqNumber;
      firstN.columnNumber = colNumber;
      fSet.push(firstN);
      seqNumber++;

      let secN = emptyFieldRow();
      secN.sequence = seqNumber;
      secN.columnNumber = colNumber;
      fSet.push(secN);
      seqNumber++;
    }
    qSet.fields = fSet;
    let newquesData = JSON.parse(JSON.stringify(responseData));
    newquesData.push(qSet);
    setResponseData(newquesData);
  };
  const deleteCurrentQuestion = async (qid, quIndex, qlength) => {
    if (qid !== null) {
      dispatch(setTriggerMatrixGrp(false));
      const record = await deleteQuestion(qid);
      if (record.data) {
        let newquesData = JSON.parse(JSON.stringify(responseData));

        // generate empty question row

        if (qlength === 1) {
          const baseMatrixField = emptyFieldRow();
          let seqNumber = 3001;
          let fSet = [];
          let qSet = [
            {
              id: null,
              dateCreated: null,
              dateModified: null,
              createdBy: null,
              modifiedBy: null,
              label: "",
              name: "",
              helptext: "",
              sequence: 0,
              fields: [],
            },
          ];
          fSet.push(baseMatrixField);
          for (let colNumber = 1; colNumber <= genCols.length; colNumber++) {
            let firstN = emptyFieldRow();
            firstN.sequence = seqNumber;
            firstN.columnNumber = colNumber;
            fSet.push(firstN);
            seqNumber++;

            let secN = emptyFieldRow();
            secN.sequence = seqNumber;
            secN.columnNumber = colNumber;
            fSet.push(secN);
            seqNumber++;
          }
          qSet[0].fields = fSet;

          newquesData = qSet;
        } else {
          newquesData.splice(quIndex, 1);
        }
        setResponseData(newquesData);
        dispatch(setTriggerMatrixGrp(true));
      }
    }
  };
  const deleteSecFieldRecord = async (fieldId, quIndex, fieldSeq, level) => {
    if (fieldId !== null) {
      dispatch(setTriggerMatrixGrp(false));
      const record = await deleteField(fieldId);
      if (record.data) {
        const fieldindex = responseData[quIndex].fields
          .map((i) => i.sequence)
          .indexOf(fieldSeq);
        setIsCreateUpdateSFields(false);

        let newquesData = JSON.parse(JSON.stringify(responseData));

        const baseMatrixField = emptyFieldRow();
        const colNum = newquesData[quIndex].fields[fieldindex].columnNumber;
        // put temp distinct seq number
        const tempSeqNum = level === "level1" ? -fieldId : fieldId;
        newquesData[quIndex].fields[fieldindex] = baseMatrixField;
        newquesData[quIndex].fields[fieldindex].columnNumber = colNum;
        newquesData[quIndex].fields[fieldindex].sequence = tempSeqNum;
        setResponseData(newquesData);
        dispatch(setTriggerMatrixGrp(true));
      }
    }
  };
  const handleSecondaryFieldChange = (e, catetory, row, mainIndex) => {
    const { value } = e.target;
    let newquesData = JSON.parse(JSON.stringify(responseData));
    const fieldindex = responseData[mainIndex].fields
      .map((i) => i.sequence)
      .indexOf(row.sequence);

    if (catetory === "sfieldname") {
      newquesData[mainIndex].fields[fieldindex].userLabel = value;
    } else if (catetory === "sfieldtype") {
      newquesData[mainIndex].fields[fieldindex].fieldType.name = value;
    }
    setIsCreateUpdateSFields(true);
    setResponseData(newquesData);
    setSecIndex({
      mainIndex: mainIndex,
      fieldindex: fieldindex,
    });
  };

  const handleQuesChange = (e, catetory, index) => {
    const { value } = e.target;
    let newquesData = JSON.parse(JSON.stringify(responseData));
    if (catetory === "userLabel") {
      newquesData[index].fields[0].userLabel = value;
    } else if (catetory === "questionLabel") {
      newquesData[index].label = value;
    } else if (catetory === "questionname") {
      newquesData[index].name = value;
    }
    setIsCreateUpdateQues(true);
    setQIndex(index);
    setResponseData(newquesData);
    setIdentity(catetory);
  };

  const handleRefreshQuestionWrapper = (record, quIndex) => {
    handleCloseEditModal();
    setIsCreateUpdateQues(false);
    let newquesData = JSON.parse(JSON.stringify(responseData));

    newquesData[quIndex].sequence = record.sequence;
    newquesData[quIndex].createdBy = record.createdBy;
    newquesData[quIndex].dateCreated = record.dateCreated;
    newquesData[quIndex].helpText = record.helpText;
    newquesData[quIndex].label = record.label;
    newquesData[quIndex].name = record.name;

    setResponseData(newquesData);
  };
  const handleCloseEditModal = () => {
    setOpenEditQuesModal(false);
    setOpenQuestion({});
  };
  const openEditQView = (row, id, index) => {
    if (id !== null) {
      setOpenQuestion({ row: row, index: index });
      setOpenEditQuesModal(true);
    }
  };
  const openEditFieldView = (row, qid, index) => {
    if (row.id !== null) {
      setOpenAttrModal(true);
      setAttrMatrixRow({ row: row, index: index, qid: qid });
    }
  };
  const openEditSecondaryFieldView = (row, mainIndex, qid) => {
    if (row.id !== null) {
      const fieldindex = responseData[mainIndex].fields
        .map((i) => i.sequence)
        .indexOf(row.sequence);

      setOpenSecAttrModal(true);
      setSecAttrRow({
        row: row,
        mainIndex: mainIndex,
        fieldindex: fieldindex,
        qid: qid,
      });
    }
  };

  const handleCloseSecAttrModal = () => {
    setOpenSecAttrModal(false);
    setSecAttrRow({});
  };
  const handleSecFieldChangeWrapper = (record, quIndex, fieldindex) => {
    handleCloseSecAttrModal();
    setIsCreateUpdateSFields(false);
    let newquesData = JSON.parse(JSON.stringify(responseData));

    newquesData[quIndex].fields[fieldindex].userLabel = record.userLabel;

    newquesData[quIndex].fields[fieldindex].sequence = record.sequence;
    // newquesData[quIndex].fields[fieldindex].columnNumber = record.columnNumber;
    newquesData[quIndex].fields[fieldindex].fieldFlow = record.fieldFlow;
    newquesData[quIndex].fields[fieldindex].historyFlag = record.historyFlag;
    newquesData[quIndex].fields[fieldindex].fillHistory = record.fillHistory;
    newquesData[quIndex].fields[fieldindex].isRequired = record.isRequired;
    newquesData[quIndex].fields[fieldindex].name = record.name;
    newquesData[quIndex].fields[fieldindex].namePosition = record.namePosition;
    newquesData[quIndex].fields[fieldindex].horizontalAlign =
      record.horizontalAlign;
    newquesData[quIndex].fields[fieldindex].minDate = record.minDate;
    newquesData[quIndex].fields[fieldindex].maxDate = record.maxDate;
    newquesData[quIndex].fields[fieldindex].maxLength = record.maxLength;
    newquesData[quIndex].fields[fieldindex].isMultiselect =
      record.isMultiselect;
    newquesData[quIndex].fields[fieldindex].maxNumeric = record.maxNumeric;
    newquesData[quIndex].fields[fieldindex].minNumeric = record.minNumeric;
    newquesData[quIndex].fields[fieldindex].size = record.size;
    newquesData[quIndex].fields[fieldindex].maxDigits = record.maxDigits;
    newquesData[quIndex].fields[fieldindex].numPrecision = record.numPrecision;
    newquesData[quIndex].fields[fieldindex].isFloat = record.isFloat;
    newquesData[quIndex].fields[fieldindex].autoCommas = record.autoCommas;
    newquesData[quIndex].fields[fieldindex].fieldValues = record.fieldValues;

    setResponseData(newquesData);
  };

  const handleCloseAttrModal = () => {
    setOpenAttrModal(false);
    setAttrMatrixRow({});
  };
  const handleFieldChangeWrapper = async (record, quIndex) => {
    handleCloseAttrModal();
    setIsCreateUpdateQues(false);
    let newquesData = JSON.parse(JSON.stringify(responseData));
    newquesData[quIndex].fields[0].columnNumber = record.columnNumber;
    newquesData[quIndex].fields[0].sequence = record.sequence;
    newquesData[quIndex].fields[0].fieldFlow = record.fieldFlow;
    newquesData[quIndex].fields[0].historyFlag = record.historyFlag;
    newquesData[quIndex].fields[0].fillHistory = record.fillHistory;
    newquesData[quIndex].fields[0].userLabel = record.userLabel;
    newquesData[quIndex].fields[0].isRequired = record.isRequired;
    newquesData[quIndex].fields[0].name = record.name;
    newquesData[quIndex].fields[0].namePosition = record.namePosition;
    newquesData[quIndex].fields[0].horizontalAlign = record.horizontalAlign;
    setResponseData(newquesData);
  };

  useEffect(() => {
    const createNewSecondaryField = async () => {
      dispatch(setTriggerMatrixGrp(false));
      let newquesData = JSON.parse(JSON.stringify(responseData));
      const selFieldtype =
        responseData[secIndex.mainIndex].fields[secIndex.fieldindex].fieldType
          .name;
      let fieldRequest = {
        fieldType: {
          name: selFieldtype,
        },
        createdBy: userName,
        columnNumber:
          responseData[secIndex.mainIndex].fields[secIndex.fieldindex]
            .columnNumber,
        fieldFlow: "NL",
        question: { id: responseData[secIndex.mainIndex].id },
        userLabel:
          responseData[secIndex.mainIndex].fields[secIndex.fieldindex]
            .userLabel,
        size: 15,
        isRequired:
          responseData[secIndex.mainIndex].fields[secIndex.fieldindex]
            .isRequired,
        fillHistory:
          responseData[secIndex.mainIndex].fields[secIndex.fieldindex]
            .fillHistory,
        name: responseData[secIndex.mainIndex].fields[secIndex.fieldindex].name,
        namePosition:
          responseData[secIndex.mainIndex].fields[secIndex.fieldindex]
            .namePosition,
      };

      if (
        selFieldtype === "Radio Field" ||
        selFieldtype === "Combobox Field" ||
        selFieldtype === "CheckBox Field"
      ) {
        const mappedResData = mapResponseData(sortedResData);

        fieldRequest = {
          ...fieldRequest,
          ...mappedResData,
        };
      } else {
        const mappedResData = { fieldValues: [] };
        fieldRequest = {
          ...fieldRequest,
          ...mappedResData,
        };
      }
      const result = await createField(fieldRequest);
      if (result?.data && !result?.data?.detail) {
        const res = result.data;
        newquesData[secIndex.mainIndex].fields[secIndex.fieldindex].id = res.id;

        newquesData[secIndex.mainIndex].fields[secIndex.fieldindex].userLabel =
          res.userLabel;

        newquesData[secIndex.mainIndex].fields[secIndex.fieldindex].createdBy =
          res.createdBy;

        newquesData[secIndex.mainIndex].fields[
          secIndex.fieldindex
        ].dateCreated = res.dateCreated;

        newquesData[secIndex.mainIndex].fields[
          secIndex.fieldindex
        ].columnNumber = res.columnNumber;

        newquesData[secIndex.mainIndex].fields[secIndex.fieldindex].sequence =
          res.sequence;

        newquesData[secIndex.mainIndex].fields[
          secIndex.fieldindex
        ].fillHistory = res.fillHistory;

        newquesData[secIndex.mainIndex].fields[
          secIndex.fieldindex
        ].historyFlag = res.historyFlag || false;

        newquesData[secIndex.mainIndex].fields[
          secIndex.fieldindex
        ].fieldType.name = res.fieldType.name;

        newquesData[secIndex.mainIndex].fields[secIndex.fieldindex].name =
          res.name;

        newquesData[secIndex.mainIndex].fields[
          secIndex.fieldindex
        ].namePosition = res.namePosition;

        newquesData[secIndex.mainIndex].fields[
          secIndex.fieldindex
        ].fieldValues = res.fieldValues;

        setResponseData(newquesData);
        setIsCreateUpdateSFields(false);
        setSecIndex({});
        dispatch(setTriggerMatrixGrp(true));

        dispatch(setSavedField(res));
      } else if (result?.data?.detail) {
        setSnackBar(result?.data?.detail);
        dispatch(setTriggerMatrixGrp(false));
        setIsCreateUpdateSFields(false);
        setSecIndex({});
      }
    };
    const updateNewSecondaryField = async () => {
      dispatch(setTriggerMatrixGrp(false));
      let newquesData = JSON.parse(JSON.stringify(responseData));
      const quesPayload = {
        question: { id: responseData[secIndex.mainIndex].id },
        modifiedBy: userName,
        id: responseData[secIndex.mainIndex].fields[secIndex.fieldindex].id,

        userLabel:
          responseData[secIndex.mainIndex].fields[secIndex.fieldindex]
            .userLabel,

        fieldType:
          responseData[secIndex.mainIndex].fields[secIndex.fieldindex]
            .fieldType,

        isRequired:
          responseData[secIndex.mainIndex].fields[secIndex.fieldindex]
            .isRequired,
        sequence:
          responseData[secIndex.mainIndex].fields[secIndex.fieldindex].sequence,
        columnNumber:
          responseData[secIndex.mainIndex].fields[secIndex.fieldindex]
            .columnNumber,
        name: responseData[secIndex.mainIndex].fields[secIndex.fieldindex].name,
        namePosition:
          responseData[secIndex.mainIndex].fields[secIndex.fieldindex]
            .namePosition,

        historyFlag:
          responseData[secIndex.mainIndex].fields[secIndex.fieldindex]
            .historyFlag,
        fillHistory:
          responseData[secIndex.mainIndex].fields[secIndex.fieldindex]
            .fillHistory,
        fieldFlow:
          responseData[secIndex.mainIndex].fields[secIndex.fieldindex]
            .fieldFlow,
        horizontalAlign:
          responseData[secIndex.mainIndex].fields[secIndex.fieldindex]
            .horizontalAlign,
        minDate:
          responseData[secIndex.mainIndex].fields[secIndex.fieldindex].minDate,
        maxDate:
          responseData[secIndex.mainIndex].fields[secIndex.fieldindex].maxDate,
        maxLength:
          responseData[secIndex.mainIndex].fields[secIndex.fieldindex]
            .maxLength,
        isMultiselect:
          responseData[secIndex.mainIndex].fields[secIndex.fieldindex]
            .isMultiselect,
        maxNumeric:
          responseData[secIndex.mainIndex].fields[secIndex.fieldindex]
            .maxNumeric,
        minNumeric:
          responseData[secIndex.mainIndex].fields[secIndex.fieldindex]
            .minNumeric,

        size: responseData[secIndex.mainIndex].fields[secIndex.fieldindex].size,
        maxDigits:
          responseData[secIndex.mainIndex].fields[secIndex.fieldindex]
            .maxDigits,
        numPrecision:
          responseData[secIndex.mainIndex].fields[secIndex.fieldindex]
            .numPrecision,
        isFloat:
          responseData[secIndex.mainIndex].fields[secIndex.fieldindex].isFloat,
        autoCommas:
          responseData[secIndex.mainIndex].fields[secIndex.fieldindex]
            .autoCommas,
        fieldValues:
          responseData[secIndex.mainIndex].fields[secIndex.fieldindex]
            .fieldValues,
      };
      try {
        const response = await updateField(quesPayload);
        if (response?.data && !response?.data?.detail) {
          const res = response?.data;
          newquesData[secIndex.mainIndex].fields[
            secIndex.fieldindex
          ].userLabel = res.userLabel;

          newquesData[secIndex.mainIndex].fields[secIndex.fieldindex].sequence =
            res.sequence;
          setResponseData(newquesData);
          setIsCreateUpdateSFields(false);
          setSecIndex({});
          dispatch(setTriggerMatrixGrp(true));
        }
      } catch (error) {
        console.log("Error updating secondary field", error);
        return null;
      }
    };
    if (responseData && secIndex && isCreateUpdateSFields) {
      const secfId =
        responseData[secIndex.mainIndex].fields[secIndex.fieldindex].id;
      const secfValue =
        responseData[secIndex.mainIndex].fields[secIndex.fieldindex].fieldType
          .name;

      if (
        responseData[secIndex.mainIndex].id &&
        (secfId === null || secfId === undefined) &&
        secfValue !== ""
      ) {
        createNewSecondaryField();
      } else if (
        !!responseData[secIndex.mainIndex].id &&
        secfId &&
        secfValue !== ""
      ) {
        updateNewSecondaryField();
      }
    }
  }, [isCreateUpdateSFields, responseData, secIndex]);

  useEffect(() => {
    const createNewQuestion = async () => {
      dispatch(setTriggerMatrixGrp(false));
      let newquesData = JSON.parse(JSON.stringify(responseData));
      const quesPayload = {
        id: null,
        questionGroup: qGroupId,
        createdBy: userName,
        label: responseData[qIndex].label,
        name: responseData[qIndex].name,
      };
      try {
        const response = await createQuestion(quesPayload);
        const newQuesId = response?.data.id;

        if (newQuesId) {
          const fieldRequest = {
            question: { id: newQuesId },
            fieldType: { name: fieldTypeName },
            userLabel: responseData[qIndex].fields[0].userLabel,
            columnNumber: 0,
            createdBy: userName,
            fieldFlow: "SL",
            historyFlag: false,
            fillHistory: true,
            name: "",
            namePosition: "",
            horizontalAlign: "",
          };
          const result = await createStandardMatrixField(fieldRequest);
          if (result?.data && !result?.data?.detail) {
            const res = result.data;
            newquesData[qIndex].id = newQuesId;
            newquesData[qIndex].sequence = response?.data.sequence;
            newquesData[qIndex].createdBy = response?.data.createdBy;
            newquesData[qIndex].dateCreated = response?.data.dateCreated;
            newquesData[qIndex].helpText = null;

            newquesData[qIndex].fields[0].id = res.id;
            newquesData[qIndex].fields[0].sequence = res.sequence;
            newquesData[qIndex].fields[0].columnNumber = res.columnNumber;
            newquesData[qIndex].fields[0].fieldFlow = res.fieldFlow;
            newquesData[qIndex].fields[0].historyFlag = res.historyFlag;
            newquesData[qIndex].fields[0].fillHistory = res.fillHistory;
            newquesData[qIndex].fields[0].userLabel = res.userLabel
              ? res.userLabel
              : null;

            newquesData[qIndex].fields[0].fieldType.name = res.fieldType.name;
            setResponseData(newquesData);
          } else if (result?.data?.detail) {
            setSnackBar(result?.data?.detail);
            const delQues = await deleteQuestion(newQuesId);
          }
        }

        setIsCreateUpdateQues(false);
        setIdentity(null);
        setQIndex(null);
        dispatch(setTriggerMatrixGrp(true));
      } catch (error) {
        console.log("Error creating question", error);
        return null;
      }
    };
    const updateQuestionRecord = async () => {
      dispatch(setTriggerMatrixGrp(false));
      const quesPayload = {
        id: responseData[qIndex].id,
        questionGroup: qGroupId,
        modifiedBy: userName,
        label: responseData[qIndex].label,
        name: responseData[qIndex].name,
        sequence: responseData[qIndex].sequence,
        helpText: responseData[qIndex].helpText,
        createdBy: responseData[qIndex].createdBy,
        dateCreated: responseData[qIndex].dateCreated,
      };
      try {
        const response = await updateQuestion(quesPayload);
        if (response?.data) {
          setIsCreateUpdateQues(false);
          setIdentity(null);
          setQIndex(null);
          dispatch(setTriggerMatrixGrp(true));
        }
      } catch (error) {
        console.log("Error creating question", error);
        return null;
      }
    };
    const updateFieldsRow = async () => {
      dispatch(setTriggerMatrixGrp(false));
      const quesPayload = {
        question: { id: responseData[qIndex].id },
        modifiedBy: userName,
        id: responseData[qIndex].fields[0].id,
        fieldType: {
          name: fieldTypeName,
        },
        columnNumber: responseData[qIndex].fields[0].columnNumber,
        userLabel: responseData[qIndex].fields[0].userLabel,
        name: responseData[qIndex].fields[0].name,
        namePosition: responseData[qIndex].fields[0].namePosition,
        historyFlag: responseData[qIndex].fields[0].historyFlag,
        fillHistory: responseData[qIndex].fields[0].fillHistory,
        fieldFlow: responseData[qIndex].fields[0].fieldFlow,
        horizontalAlign: responseData[qIndex].fields[0].horizontalAlign,
        sequence: responseData[qIndex].fields[0].sequence,
        isRequired: responseData[qIndex].fields[0].isRequired,
      };

      try {
        const response = await updateField(quesPayload);
        setIsCreateUpdateQues(false);
        setIdentity(null);
        setQIndex(null);
        dispatch(setTriggerMatrixGrp(true));
      } catch (error) {
        console.log("Error updating field", error);
        return null;
      }
    };
    if (
      responseData &&
      isCreateUpdateQues &&
      qGroupId !== null &&
      responseData[qIndex].id === null &&
      responseData[qIndex].fields[0].userLabel &&
      responseData[qIndex].name
    ) {
      createNewQuestion();
    } else if (
      responseData &&
      isCreateUpdateQues &&
      responseData[qIndex].id !== null &&
      responseData[qIndex].fields[0].userLabel &&
      responseData[qIndex].name
    ) {
      if (identity === "userLabel") {
        updateFieldsRow();
      } else if (identity === "questionLabel" || identity === "questionname") {
        updateQuestionRecord();
      }
    }
  }, [isCreateUpdateQues, responseData, qIndex, identity]);

  const setSnackBar = (msg) => {
    setSuccessSnackbarOpen(true);
    setAlertMsg(msg);
    setAlertSeverity("error");
  };
  return (
    <>
      {responseData && responseData.length > 0 && (
        <TableRow>
          <TableCell colSpan="3">
            <Table>
              <TableHead>
                <TableRow
                  sx={{
                    "& th": {
                      color: theme.palette.blue.main,
                      fontWeight: "bold",
                      fontSize: "13px",
                      padding: "7px",
                    },
                  }}
                >
                  <TableCell></TableCell>
                  <TableCell>Question Label / Text *</TableCell>
                  <TableCell>Field Label *</TableCell>
                  {showResponseHeaders(genCols)}
                </TableRow>
              </TableHead>
              <TableBody>
                {responseData.map((row, index) => (
                  <TableRow
                    key={`tbl ${index}`}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell>
                      <Tooltip title="Delete question" arrow>
                        <HighlightOffOutlinedIcon
                          sx={{
                            cursor: "pointer",
                            width: 17,
                            height: 17,
                            color: theme.palette.blue.main,
                          }}
                          onClick={() => {
                            deleteCurrentQuestion(
                              row.id,
                              index,
                              responseData.length
                            );
                          }}
                        />
                      </Tooltip>
                    </TableCell>
                    <TableCell width="16%">
                      <Stack direction="row" spacing={1}>
                        <TextField
                          height="small"
                          value={row.label}
                          variant="outlined"
                          style={{ width: "100" }}
                          onBlur={(event) =>
                            handleQuesChange(event, "questionLabel", index)
                          }
                        />
                        <TextField
                          height="small"
                          variant="outlined"
                          value={getTextFromHTML(row.name)}
                          style={{ width: "100" }}
                          onBlur={(event) =>
                            handleQuesChange(event, "questionname", index)
                          }
                        />
                        <Tooltip title="Edit question" arrow>
                          <NoteAltOutlinedIcon
                            sx={{
                              cursor: "pointer",
                              width: 17,
                              height: 17,
                              color: theme.palette.blue.main,
                            }}
                            onClick={() => {
                              openEditQView(row, row.id, index);
                            }}
                          />
                        </Tooltip>
                      </Stack>
                    </TableCell>
                    <TableCell width="10%">
                      <Stack direction="row" spacing={1}>
                        <TextField
                          height="small"
                          value={row.fields[0].userLabel || ""}
                          style={{ width: "100" }}
                          variant="outlined"
                          onBlur={(event) =>
                            handleQuesChange(event, "userLabel", index)
                          }
                          helperText={!row.id ? "Enter question" : ""}
                        />
                        <Tooltip title="Edit field" arrow>
                          <NoteAltOutlinedIcon
                            sx={{
                              cursor: "pointer",
                              width: 16,
                              height: 16,
                              color: theme.palette.blue.main,
                            }}
                            onClick={() => {
                              openEditFieldView(row.fields[0], row.id, index);
                            }}
                          />
                        </Tooltip>
                      </Stack>
                    </TableCell>
                    {genCols.map((data, sindex) => {
                      const filteredColumns = row.fields.filter(
                        (line) => line.columnNumber === data
                      );
                      const filteredObj = filteredColumns.sort(
                        (a, b) => a.sequence - b.sequence
                      );
                      if (filteredObj.length > 0) {
                        return (
                          <>
                            <TableCell key={`tblcell ${sindex}`}>
                              <Stack direction="row" spacing={1}>
                                <TextField
                                  height="small"
                                  style={{ width: "99%" }}
                                  variant="outlined"
                                  disabled={row.id == null}
                                  value={filteredObj[0].userLabel || ""}
                                  onBlur={(event) =>
                                    handleSecondaryFieldChange(
                                      event,
                                      "sfieldname",
                                      filteredObj[0],
                                      index
                                    )
                                  }
                                />
                                <FormControl sx={{ width: "99%" }}>
                                  <Select
                                    size="small"
                                    variant="outlined"
                                    disabled={
                                      row.id == null ||
                                      filteredObj[0].id !== null
                                    }
                                    value={filteredObj[0].fieldType.name}
                                    onChange={(event) =>
                                      handleSecondaryFieldChange(
                                        event,
                                        "sfieldtype",
                                        filteredObj[0],
                                        index
                                      )
                                    }
                                  >
                                    {fieldTypeOptions.map(
                                      (option, ftindex1) => (
                                        <MenuItem
                                          key={`ftype${ftindex1}`}
                                          value={option.name}
                                        >
                                          {option.name}
                                        </MenuItem>
                                      )
                                    )}
                                  </Select>
                                </FormControl>
                                <Tooltip title="Edit secondary field" arrow>
                                  <NoteAltOutlinedIcon
                                    sx={{
                                      cursor: "pointer",
                                      width: 17,
                                      height: 17,
                                      color: theme.palette.blue.main,
                                    }}
                                    onClick={() => {
                                      openEditSecondaryFieldView(
                                        filteredObj[0],
                                        index,
                                        row.id
                                      );
                                    }}
                                  />
                                </Tooltip>
                                <Tooltip title="Delete secondary field" arrow>
                                  <HighlightOffOutlinedIcon
                                    sx={{
                                      cursor: "pointer",
                                      width: 17,
                                      height: 17,
                                      color: theme.palette.blue.main,
                                    }}
                                    onClick={() => {
                                      deleteSecFieldRecord(
                                        filteredObj[0].id,
                                        index,
                                        filteredObj[0].sequence,
                                        "level1"
                                      );
                                    }}
                                  />
                                </Tooltip>
                              </Stack>
                              <br></br>
                              <Stack direction="row" spacing={1}>
                                <TextField
                                  height="small"
                                  sx={{ width: "99%" }}
                                  variant="outlined"
                                  disabled={row.id == null}
                                  value={filteredObj[1].userLabel || ""}
                                  onBlur={(event) =>
                                    handleSecondaryFieldChange(
                                      event,
                                      "sfieldname",
                                      filteredObj[1],
                                      index
                                    )
                                  }
                                />
                                <FormControl sx={{ width: "99%" }}>
                                  <Select
                                    size="small"
                                    variant="outlined"
                                    disabled={
                                      row.id == null ||
                                      filteredObj[1].id !== null
                                    }
                                    sx={{ margin: "none" }}
                                    value={filteredObj[1].fieldType.name}
                                    onChange={(event) =>
                                      handleSecondaryFieldChange(
                                        event,
                                        "sfieldtype",
                                        filteredObj[1],
                                        index
                                      )
                                    }
                                  >
                                    {fieldTypeOptions.map(
                                      (option, ftindex2) => (
                                        <MenuItem
                                          key={`ftype2${ftindex2}`}
                                          value={option.name}
                                        >
                                          {option.name}
                                        </MenuItem>
                                      )
                                    )}
                                  </Select>
                                </FormControl>

                                <Tooltip title="Edit secondary field" arrow>
                                  <NoteAltOutlinedIcon
                                    sx={{
                                      cursor: "pointer",
                                      width: 17,
                                      height: 17,
                                      color: theme.palette.blue.main,
                                    }}
                                    onClick={() => {
                                      openEditSecondaryFieldView(
                                        filteredObj[1],
                                        index,
                                        row.id
                                      );
                                    }}
                                  />
                                </Tooltip>
                                <Tooltip title="Delete secondary field" arrow>
                                  <HighlightOffOutlinedIcon
                                    sx={{
                                      cursor: "pointer",
                                      width: 17,
                                      height: 17,
                                      color: theme.palette.blue.main,
                                    }}
                                    onClick={() => {
                                      deleteSecFieldRecord(
                                        filteredObj[1].id,
                                        index,
                                        filteredObj[1].sequence,
                                        "level2"
                                      );
                                    }}
                                  />
                                </Tooltip>
                              </Stack>
                            </TableCell>
                          </>
                        );
                      }
                    })}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableCell>
        </TableRow>
      )}
      <Typography
        color="primary"
        className="blueLinkText"
        variant="caption"
        sx={{ mt: 2, ml: 2 }}
        onClick={() => {
          addNewQuestion();
        }}
      >
        &#10011; Add row
      </Typography>
      <Modal open={openAttrModal} onClose={handleCloseAttrModal}>
        <CheckboxAttributes
          handleCloseAttrView={handleCloseAttrModal}
          handleFieldChangeWrapper={handleFieldChangeWrapper}
          fieldTypeName={fieldTypeName}
          attrRow={attrMatrixRow}
        />
      </Modal>
      <Modal open={openEditQuesModal} onClose={handleCloseEditModal}>
        <QuickQuestionEdit
          handleCloseEditModal={handleCloseEditModal}
          handleRefreshQuestionWrapper={handleRefreshQuestionWrapper}
          openQuestion={openQuestion}
          qGroupId={qGroupId}
        />
      </Modal>
      <Modal open={openSecAttrModal} onClose={handleCloseSecAttrModal}>
        <SecFieldAttributes
          handleCloseSecAttrView={handleCloseSecAttrModal}
          handleSecFieldChangeWrapper={handleSecFieldChangeWrapper}
          attrRow={secAttrRow}
          selectedField={selectedField}
        />
      </Modal>
      <Snackbar
        open={successSnackbarOpen}
        autoHideDuration={4000}
        onClose={() => setSuccessSnackbarOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          onClose={() => setSuccessSnackbarOpen(false)}
          severity={alertSeverity}
          variant="filled"
        >
          {alertMsg}
        </MuiAlert>
      </Snackbar>
    </>
  );
}
export default MultipleResponseTable;
